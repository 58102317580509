/* eslint-disable react-native/no-inline-styles */
import {
  LinearGradientBetsSharColor,
  shardImg,
  Subtract,
} from './bets-shard.variable';
import {View, ImageBackground} from 'react-native';
import Text from '@basicComponents/text';
import {basicColor, flex, fontSize, margin} from '@/components/style';
import React from 'react';
import LazyImage from '@/components/basic/image';

import {SafeAny} from '@/types';
import LinearGradient from '@basicComponents/linear-gradient';
// import BetsShardModal from './bets-shard-modal';
// import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import BetsShardUser from './bets-shard-user';
import BetsShardInfo from './bets-shard-info';
// import BetsShardInvite from './bets-shard-invite';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import theme from '@/style';

const BetsShardWinning = ({orderInfo, userInfo, isAnna}: SafeAny) => {
  const {calcActualSize, screenHeight, screenWidth} = useScreenSize();
  // const BetsShardModalRef: SafeAny = useRef(null);
  return (
    <>
      <ImageBackground
        style={[
          {
            width: screenWidth,
            height: screenHeight,
          },
          theme.position.abs,
        ]}
        source={{uri: shardImg}}
      />
      <View
        style={[
          {
            width: '100%',
            padding: calcActualSize(theme.paddingSize.xxl),
          },
        ]}>
        <LinearGradient
          style={[
            {
              width: '100%',
              borderTopLeftRadius: calcActualSize(8),
              borderTopRightRadius: calcActualSize(8),
              borderBottomLeftRadius: calcActualSize(12),
              borderBottomRightRadius: calcActualSize(12),
              paddingHorizontal: calcActualSize(theme.paddingSize.xxl),
              paddingVertical: calcActualSize(theme.paddingSize.s),
            },
          ]}
          start={{x: 0, y: 0}}
          end={{x: 0, y: 1}}
          colors={LinearGradientBetsSharColor}>
          <BetsShardUser userInfo={userInfo} />
          <View
            style={[
              flex.flex,
              flex.center,
              flex.row,
              {
                marginTop: calcActualSize(theme.paddingSize.s),
              },
            ]}>
            <Text
              calc
              color={basicColor.white}
              fontSize={fontSize.l}
              blod
              fontFamily="fontInter">
              I'm luck today!
            </Text>
          </View>
          <View
            style={[
              flex.flex,
              flex.center,
              flex.row,
              {
                marginTop: calcActualSize(theme.paddingSize.xxs),
              },
            ]}>
            <Text
              calc
              color={basicColor.dark}
              fontSize={fontSize.s}
              blod
              style={[margin.rightxxs]}
              fontFamily="fontInter">
              Won
            </Text>
            <Text
              calc
              color={basicColor.primary}
              fontSize={fontSize.xl}
              blod
              fontFamily="fontDin">
              ₹{orderInfo?.totalAwardAmount}
            </Text>
          </View>
        </LinearGradient>
        <BetsShardInfo
          top
          borderBottomRadius={0}
          borderTopRadius={12}
          orderInfo={orderInfo}
          isAnna={isAnna}
          result={
            <View style={[flex.flex, flex.centerByCol, flex.between, flex.row]}>
              <Text
                calc
                color={basicColor.dark}
                fontSize={fontSize.s}
                blod
                fontFamily="fontInter">
                Won
              </Text>
              <Text
                calc
                style={[margin.leftxxs]}
                color={basicColor.primary}
                fontSize={14}
                blod
                fontFamily="fontDin">
                ₹{orderInfo?.totalAwardAmount}
              </Text>
            </View>
          }
        />
        <View style={[{marginTop: -1}]}>
          <LazyImage
            imageUrl={Subtract}
            occupancy="#0000"
            width={'100%'}
            height={calcActualSize(24)}
          />
        </View>
        {/* <View
          style={[
            {
              backgroundColor: 'rgba(0, 0, 0, 0.50)',
            },
            margin.topl,
            borderRadius.xl,
          ]}>
          <BetsShardInvite
            width={'100%'}
            userInviteCode={userInfo?.userInviteCode}
            close={true}
          />
        </View> */}
      </View>
      {/* <View style={[padding.lrl, position.abs, {bottom: 24, width: '100%'}]}>
        <View
          style={[
            {width: '100%'},
            flex.center,
            {
              height: 48,
              borderRadius: 30,
              overflow: 'hidden',
            },
          ]}>
          <LinearGradient
            style={[
              {
                width: '100%',
                height: 48,
                paddingLeft: 12,
                paddingRight: 12,
              },
              flex.center,
            ]}
            start={{x: 0, y: 1}}
            end={{x: 1, y: 0}}
            colors={['#8700DA', '#7000FF']}>
            <NativeTouchableOpacity
              // onPress={() => BetsShardWinningModalRef?.current?.showModal()}>
              // 跳转对应游戏
              onPress={() => {
                console.log(
                  `%c 跳转${params.gameCode}游戏`,
                  'color: red; font-size: 20px',
                );
              }}>
              <Text
                fontSize={fontSize.l}
                color={basicColor.white}
                fontWeight="bold">
                Try To Win A Big Prize
              </Text>
            </NativeTouchableOpacity>
          </LinearGradient>
        </View>
      </View> */}
      {/* <BetsShardModal ref={BetsShardModalRef} /> */}
    </>
  );
};
export default BetsShardWinning;
