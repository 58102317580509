/* eslint-disable react-native/no-inline-styles */
import {View} from 'react-native';
import {flex} from '@/components/style';
import React from 'react';
import Text from '@basicComponents/text';
import theme from '@/style';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

const abcColors = ['#DE3C3F', '#EC8305', '#046FEA'];

const getHeader = (title: string, isRight: boolean = false) => (
  <Text
    calc
    second
    fontWeight="500"
    style={[isRight && {textAlign: 'right'}]}
    fontSize={12}>
    {title}
  </Text>
);

const ResultItem = ({
  gameCode,
  backgroundColor = '#E9ECF5',
  renderList = [],
  height,
}: {
  height: number;
  gameCode: string;
  backgroundColor?: string;
  renderList?: React.ReactNode[];
}) => {
  const {calcActualSize} = useScreenSize();
  const len = renderList.length;
  const getNumberWidth = () => {
    switch (gameCode) {
      case 'color': {
        return 126;
      }
      case 'scratch': {
        return 124;
      }
      case 'dice': {
        return 52;
      }
      case 'stateLottery':
      case 'quickStateLottery': {
        return 96;
      }
      default:
        return 96;
    }
  };
  const ABC = (
    <View
      style={[
        theme.flex.flex,
        theme.flex.row,
        {
          gap: calcActualSize(4),
        },
      ]}>
      {['A', 'B', 'C'].map((item, index) => (
        <View
          style={[
            theme.flex.flex,
            theme.flex.center,
            {
              width: calcActualSize(24),
              height: calcActualSize(24),
              borderRadius: calcActualSize(24),
              backgroundColor: abcColors[index],
            },
          ]}
          key={item}>
          <Text
            calc
            color={theme.basicColor.white}
            fontWeight="700"
            fontSize={12}>
            {item}
          </Text>
        </View>
      ))}
    </View>
  );
  return (
    <View
      style={[
        theme.padding.lrl,
        flex.between,
        flex.row,
        flex.centerByCol,
        {backgroundColor, height: calcActualSize(height)},
      ]}>
      {['3Digit', 'quick3d'].includes(gameCode) ? (
        <View style={[{width: calcActualSize(96)}]}>
          {len ? renderList[0] : ABC}
        </View>
      ) : (
        <View
          style={[
            {
              width: calcActualSize(getNumberWidth()),
            },
          ]}>
          {len ? renderList[0] : getHeader('NUMBER')}
        </View>
      )}
      {gameCode === 'dice' && (
        <View style={[{width: calcActualSize(72)}, flex.center]}>
          {len ? renderList[1] : getHeader('GAMETYPE')}
        </View>
      )}
      {gameCode.includes('tateLottery') && (
        <View style={[{width: calcActualSize(90)}, flex.center]}>
          {len ? renderList[1] : getHeader('TYPE')}
        </View>
      )}
      <View
        style={[
          {
            width: gameCode.includes('tateLottery')
              ? calcActualSize(72)
              : calcActualSize(96),
          },
          flex.center,
        ]}>
        {len ? renderList[len - 2] : getHeader('PARMENT')}
      </View>
      <View style={[{width: calcActualSize(72)}, flex.end]}>
        {len ? renderList[len - 1] : getHeader('RESULT', true)}
      </View>
    </View>
  );
};
export default ResultItem;
